.s-about-numbers {
  --borderPrimary: var(--borderNeutralSubtlest);
  --pseudoClassesPrimary: var(--surfaceNeutralInverseDefault);
  --pseudoClassesSecondary: var(--danger);
}

.s-about-numbers__wrapper-body-table {
  margin-bottom: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 3.25rem;
}

.s-about-numbers__wrapper-pre-title-carousel {
  margin-bottom: 11.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3.625rem;
}

.s-about-numbers__pre-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-family: InterMedium;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.s-about-numbers__pre-title::before {
  margin: 0.5rem;
  height: 0.3125rem;
  width: 0.3125rem;
  border-radius: 9999px;
  background-color: var(--pseudoClassesSecondary);
  content: "";
}

.s-about-numbers__pre-title::after {
  height: 0.125rem;
  width: 9.875rem;
  background-color: var(--pseudoClassesPrimary);
  --tw-content: "";
  content: var(--tw-content);
}

.s-about-numbers__carousel {
  margin-bottom: -11.625rem;
}

.s-about-numbers__title {
  margin-bottom: 8.3125rem;
  font-family: InterBold;
  font-size: 12.5rem;
  text-transform: uppercase;
  line-height: 3.5rem;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  letter-spacing: -0.0025em;
}

.s-about-numbers__wrapper-text-button {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 29.8125rem 1fr;
  grid-template-columns: 29.8125rem 1fr;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 6.25rem;
}

.s-about-numbers-button {
  height: 9rem;
  width: 9rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.s-about-numbers-button .text {
  font-family: InterSemiBold;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.0066666667em;
}

.s-about-numbers__text {
  font-size: 0.875rem;
  line-height: 1.375rem;
  opacity: 0.6;
  letter-spacing: 0;
}

.s-about-numbers__table {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-width: 1px;
  border-style: solid;
  border-color: var(--borderPrimary);
}

.s-about-numbers__table__item {
  display: -ms-grid;
  display: grid;
  width: 17.3125rem;
  -ms-grid-rows: 3rem 2.6875rem 9.5625rem;
  grid-template-rows: 3rem 2.6875rem 9.5625rem;
  padding: 1rem;
}

.s-about-numbers__table__item:nth-child(odd) {
  border-right-width: 1px;
  border-style: solid;
  border-color: var(--borderPrimary);
}

.s-about-numbers__table__item:nth-child(3),
.s-about-numbers__table__item:nth-child(4) {
  border-top-width: 1px;
  border-style: solid;
  border-color: var(--borderPrimary);
}

.s-about-numbers__table__item-top {
  margin-bottom: 2rem;
  font-size: 0.75rem;
  line-height: 1rem;
  opacity: 0.4;
  letter-spacing: 0.0333333333em;
}

.s-about-numbers__table__item-medium {
  -ms-flex-item-align: end;
  align-self: flex-end;
  text-align: center;
  line-height: 1.5rem;
  opacity: 0.72;
  letter-spacing: -0.04em;
}

.s-about-numbers__table__item-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
  text-align: center;
  font-size: 3.125rem;
  line-height: 120%;
  letter-spacing: -0.04em;
}

.s-about-numbers__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.s-about-numbers__list li {
  position: relative;
  padding-top: 12.8%;
}

.s-about-numbers__list li img,
.s-about-numbers__list li video,
.s-about-numbers__list li svg {
  position: absolute;
  inset: 0px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 105em) {
  .s-about-numbers__wrapper-body-table {
    margin-bottom: 0.75rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1.25rem;
  }
}

@media (max-width: 89.9988em) {
  .s-about-numbers__title {
    font-size: 9.375rem;
  }

  .s-about-numbers__wrapper-text-button {
    -ms-grid-columns: 21.25rem 1fr;
    grid-template-columns: 21.25rem 1fr;
  }
}

@media (max-width: 47.9988em) {
  .s-about-numbers__wrapper-pre-title-carousel {
    margin-bottom: 0.625rem;
  }

  .s-about-numbers__pre-title {
    width: 100%;
  }

  .s-about-numbers__pre-title::after {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }

  .s-about-numbers__carousel {
    display: none;
  }

  .s-about-numbers__title {
    margin-bottom: 0.625rem;
    font-size: 3.5rem;
    line-height: 4rem;
    letter-spacing: -0.0267857143em;
  }

  .s-about-numbers__wrapper-text-button {
    display: block;
  }

  .s-about-numbers-button {
    display: none;
  }
}

@media (max-width: 35.998em) {
  .s-about-numbers__pre-title::after {
    width: auto;
  }

  .s-about-numbers__table__item {
    height: 11.25rem;
    max-height: 100%;
    width: 11.25rem;
    max-width: 100%;
    -ms-grid-rows: 2rem 2.75rem 1fr;
    grid-template-rows: 2rem 2.75rem 1fr;
  }

  .s-about-numbers__table__item-top {
    margin-bottom: 0px;
    line-height: 1.125rem;
    letter-spacing: 0.03em;
  }

  .s-about-numbers__table__item-medium {
    margin-bottom: 0px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .s-about-numbers__table__item-bottom {
    font-family: InterBold;
    font-size: 2rem;
    letter-spacing: -0.0625em;
  }
}

@media (prefers-color-scheme: dark) {
  .s-about-numbers {
    --borderPrimary: var(--borderNeutralSubtlest);
    --pseudoClassesPrimary: var(--surfaceNeutralInverseDefault);
    --pseudoClassesSecondary: var(--danger);
  }
}